import { faArrowRight, faDotCircle, faDrawPolygon, faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as turf from "@turf/turf";
import { bearing, point } from "@turf/turf";
import CssFilterConverter from "css-filter-converter";
import { addMinutes, isAfter, isBefore, isSameDay, isWithinInterval, subMinutes } from "date-fns";
import { MapboxStyleDefinition } from "mapbox-gl-style-switcher";
import moment from "moment";
import React from "react";
import { globalAppConfig } from "../../config";

export function areaOpen(area: any, date: any) {
    let filterDate = date;

    if (moment.isMoment(date)) {
        filterDate = date.toDate();
    } else {
        filterDate = new Date(date);
    }

    // Open and close dates currently stored in backend using Ruby Date object that has no tz info.
    // When this is converted to a date object on the frontend it doesn't have a tz so it uses UTC then offsets it to the system time.
    // This causes issues in actual comparisons down the road so we're offsetting it manually back below.
    // BIG TODO: Fix this in the backend to store the dates in datetime so it doesn't require this wonkyness.

    // Area has an open date but no end date. i.e. Location doesn't open until this date.
    if (area.properties.plot.open_date) {
        let openDate = new Date(area.properties.plot.open_date);
        openDate = subMinutes(openDate, openDate.getTimezoneOffset());

        // If the filterDate is before the openDate then that Area is not open yet.
        if (isBefore(filterDate, openDate)) {
            return false;
        }
    }
    // Area has a close date but open date is empty. i.e. area is closed until further notice after this date.
    if (area.properties.plot.close_date) {
        let closeDate = new Date(area.properties.plot.close_date);
        closeDate = addMinutes(closeDate, closeDate.getTimezoneOffset());

        // If the filterDate is after the closeDate then that Area is not open.
        if (isAfter(filterDate, closeDate) || isSameDay(filterDate, closeDate)) {
            return false;
        }
    }

    // Exception Days
    if (area.properties.plot.area_exception_days && area.properties.plot.area_exception_days.length > 0) {
        area.properties.plot.area_exception_days.forEach((exception) => {
            // Exception day start and end provided. Today is in the window provided
            if (
                exception.exception_start &&
                exception.exception_end &&
                isWithinInterval(filterDate, {
                    start: new Date(exception.exception_start),
                    end: new Date(exception.exception_end),
                })
            ) {
                return false;
            }
            // Temp closure provided start date but no end date. i.e. Closed until further notice.
            if (
                exception.exception_start &&
                !exception.exception_end &&
                (isAfter(filterDate, new Date(exception.exception_start)) ||
                    isSameDay(filterDate, new Date(exception.exception_start)))
            ) {
                return false;
            }
            // Temp closure provided end date but no start date. i.e. Closed until this date.
            if (
                !exception.exception_start &&
                exception.exception_end &&
                isBefore(filterDate, new Date(exception.exception_end))
            ) {
                return false;
            }
        });
    }
    return true;
}

export function addressFormater(projectInfo) {
    let addressString = "";
    const addressValues = [
        projectInfo.address_1,
        projectInfo.address_2,
        projectInfo.city,
        projectInfo.state,
        projectInfo.zip_code,
    ];

    addressValues.forEach((val) => {
        if (val) {
            addressString += val;
            if (val === (projectInfo.address_1 || projectInfo.address_2 || projectInfo.city)) {
                addressString += ", ";
            } else {
                addressString += " ";
            }
        }
    });

    return addressString;
}

export function iconType(objectType: string) {
    if (objectType === "Point") {
        return faDotCircle;
    } else if (objectType === "Polygon") {
        return faDrawPolygon;
    } else {
        return faArrowRight;
    }
}

export function areaAvatar(area, size: string = "1rem") {
    if (area.properties.icon) {
        const style = {
            width: size,
            filter: area.properties.icon.includes("sdf")
                ? CssFilterConverter.hexToFilter(area.properties.color).color
                : null,
        };
        return <img style={style} src={`/img/png/map/${area.properties.icon}.png`} className="mr-2" />;
    }

    if (area.properties?.plot?.area_name === "Jobsite Entrance" && !area.properties.icon) {
        return <FontAwesomeIcon icon={faMapMarkerAlt} color={area.properties.color} width={size} className="mr-2" />;
    } else {
        return (
            <FontAwesomeIcon
                icon={this.iconType(area.geometry.type)}
                color={area.geometry.type === "LineString" ? area.properties.outline : area.properties.color}
                width={size}
                className="mr-2"
            />
        );
    }
}

export function geocoderHelperResultFormatter(result) {
    result.context.push({
        id: result.id,
        text: result.text,
    });
    const location = {
        name: null,
        address: null,
        city: null,
        zip: null,
        state: null,
        country: null,
        lon: result.geometry.coordinates[0],
        lat: result.geometry.coordinates[1],
    };
    if (result.place_type.includes("address")) {
        location.address = result.place_name.substring(0, result.place_name.indexOf(","));
    }
    if (result.place_type.includes("poi")) {
        location.address = result.properties.address;
        location.name = result.place_name.substring(0, result.place_name.indexOf(","));
    }
    location.city = result.context.find((x) => {
        return x.id.startsWith("place");
    }).text;
    if (
        result.context.find((x) => {
            return x.id.startsWith("postcode");
        })
    ) {
        location.zip = result.context.find((x) => {
            return x.id.startsWith("postcode");
        }).text;
    }
    location.state = result.context.find((x) => {
        return x.id.startsWith("region");
    }).text;
    location.country = result.context.find((x) => {
        return x.id.startsWith("country");
    }).text;
    return location;
}

export function validateZipCode(val) {
    return /(^\d{5}$)|(^\d{5}-\d{4}$)/.test(val);
}

export function findLayerIndex(array, val) {
    return array.findIndex((layer) => layer.features.find((feature) => feature.id === val));
}
export function findAreaIndex(area, val) {
    return area[this.findLayerIndex(area, val)].features.findIndex((area) => area.id === val);
}
export function findObjectById(id, layers) {
    const result = layers[this.findLayerIndex(layers, id)].features[this.findAreaIndex(layers, id)];
    return result;
}

export function breakTimesUpdate(break_times, currentObject, currentLayers, callBack) {
    const object = currentObject;
    object.properties.plot.area_break_times = break_times;
    const layers = currentLayers;
    layers[this.findLayerIndex(layers, object.id)].features[this.findAreaIndex(layers, object.id)] = object;
    callBack("map_layers", layers);
}
export function exceptionDaysUpdate(exception_days, currentObject, currentLayers, callBack) {
    const object = currentObject;
    object.properties.plot.area_exception_days = exception_days;
    const layers = currentLayers;
    layers[this.findLayerIndex(layers, object.id)].features[this.findAreaIndex(layers, object.id)] = object;
    callBack("map_layers", layers);
}

export function returnCoordinates(object) {
    let coordinates = [];
    if (object.geometry.type === "Point") {
        coordinates = object.geometry.coordinates;
    } else if (object.geometry.type === "LineString") {
        coordinates = object.geometry.coordinates[0];
    } else {
        coordinates = object.geometry.coordinates[0][0];
    }
    return coordinates;
}

export function isMobileDevice() {
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        return true;
    } else {
        return false;
    }
}

export function openMapLink(object) {
    const coordinates = this.returnCoordinates(object);

    const userAgent = navigator.userAgent;

    // iOS detection from: http://stackoverflow.com/a/9039885/177710
    if (/iPad|iPhone|iPod/.test(userAgent)) {
        window.open(`maps://maps.google.com/maps?daddr=${coordinates[1]}, ${coordinates[0]}&amp;ll=`);
    } else {
        /* else use Google */
        window.open(`https://www.google.com/maps/search/?api=1&query=${coordinates[1]},${coordinates[0]}`);
    }
}

export const addMapMarkerImages = async (map, markerImages) => {
    const imagesLoaded = Promise.all(
        markerImages
            .filter((icon) => icon !== undefined && icon !== "")
            .map((icon) => {
                map.loadImage(`/img/png/map/${icon}.png`, (error, image) => {
                    // if (error) throw error;
                    // Add the image to the map style.
                    if (!map.hasImage(icon)) {
                        map.addImage(icon, image, { sdf: icon.includes("sdf") ? true : false });
                    }
                });
            })
    );
    return imagesLoaded;
};

export const addMapGeoJson = async (map, layers) => {
    const sourcesAdded = await Promise.all(
        layers.map((layer) => {
            if (map.getSource(layer.properties?.id?.toString())) {
                return;
            }
            return map.addSource(layer.properties?.id?.toString(), {
                type: "geojson",
                data: layer,
            });
        })
    );
    return sourcesAdded;
};

export const iconCheck = async (map: mapboxgl.Map, object: any, sdf_check: boolean = false) => {
    const iconSize =
        object.properties.icon && object.properties.icon_width
            ? await getIconRealWorldSize(map, object)
            : defaultIconSizeArray(sdf_check);

    return iconSize;
};

export const getIconRealWorldSize = async (map, mapObject) => {
    const img = await addImageProcess(`/img/png/map/${mapObject.properties.icon}.png`);
    const y = map.getCanvas().height;
    const x = map.getCanvas().width;
    const left = map.unproject([0, y]);
    const bottom = map.unproject([x, y]);
    const maxMeters =
        turf.distance(turf.point([left.lng, left.lat]), turf.point([bottom.lng, bottom.lat]), {
            units: "miles",
        }) * 1609.344;
    const metersPerPixel = maxMeters / x;
    let size = mapObject.properties.icon_width;
    // Convert size to feet by default unless meters is set
    if (mapObject.properties.icon_units !== "meters") {
        size = size * 0.3048;
    }
    const iconPixels = size / metersPerPixel;
    const iconSize = iconPixels / img;
    if (iconSize || iconSize !== undefined || iconSize !== Infinity) {
        return iconSize;
    } else {
        return 1;
    }
};

const addImageProcess = async (src) => {
    return new Promise((resolve, reject) => {
        const img = new Image();
        img.onload = () => resolve(img.height);
        img.onerror = reject;
        img.src = src;
    });
};

export const defaultIconSizeArray = (iconType: boolean) => {
    const svgIconSizeArray = ["interpolate", ["linear"], ["zoom"], 12, 0, 13, 0.1, 14, 0.5, 16, 1, 17, 0.5];
    const iconSizeArray = ["interpolate", ["linear"], ["zoom"], 12, 0, 13, 0.1, 14, 0.2, 16, 0.25];

    if (iconType) {
        return iconSizeArray;
    } else {
        return svgIconSizeArray;
    }
};
export const getStartCapBearing = (object) => {
    const point1 = point(object.geometry.coordinates[1]);
    const point2 = point(object.geometry.coordinates[0]);
    const mapBearing = bearing(point1, point2);
    return mapBearing;
};

export const getEndCapBearing = (object) => {
    const point1 = point(object.geometry.coordinates[object.geometry.coordinates.length - 2]);
    const point2 = point(object.geometry.coordinates[object.geometry.coordinates.length - 1]);
    const mapBearing = bearing(point1, point2);
    return mapBearing;
};

export const mapStyles: MapboxStyleDefinition[] = [
    {
        title: "Satellite-ESRI",
        uri: globalAppConfig.ARC_GIS_IMAGERY_URL,
    },
    {
        title: "Satellite-Mapbox",
        uri: `mapbox://styles/mapbox/satellite-streets-v11`,
    },
    {
        title: "Streets",
        uri: "mapbox://styles/mapbox/streets-v11",
    },
    {
        title: "Dark",
        uri: "mapbox://styles/mapbox/dark-v10",
    },
    {
        title: "Light",
        uri: "mapbox://styles/mapbox/light-v10",
    },
    {
        title: "Outdoors",
        uri: "mapbox://styles/mapbox/outdoors-v11",
    },
];

export const getMapObjectCenter = (object) => {
    switch (object.geometry.type) {
        case "Point":
            return object.geometry.coordinates;
        case "LineString": {
            // Find middle of the linestring using the coordinates array middle and use that
            const midpointIndex = Math.round((object.geometry.coordinates.length - 1) / 2);
            const midpoint = object.geometry.coordinates[midpointIndex];
            return midpoint;
        }
        default: {
            // Calc the center of the polygon and use that
            const firstCoordinate = object.geometry.coordinates[0];
            return turf.center(turf.points(firstCoordinate)).geometry.coordinates;
        }
    }
};

export const addTrafficLayer = (map: mapboxgl.Map) => {
    document.getElementsByClassName("mapboxgl-ctrl-traffic")[0].classList.add("mapboxgl-ctrl-active");
    if (map.getSource("mapbox-traffic")) {
        map.addLayer({
            id: "traffic",
            source: "mapbox-traffic",
            "source-layer": "traffic",
            type: "line",
            paint: {
                "line-width": 2,
                "line-color": [
                    "case",
                    ["==", "low", ["get", "congestion"]],
                    "#00FF00",
                    ["==", "moderate", ["get", "congestion"]],
                    "#FFFF00",
                    ["==", "heavy", ["get", "congestion"]],
                    "#FFA500",
                    ["==", "severe", ["get", "congestion"]],
                    "#FF0000",
                    "#000000",
                ],
            },
        });
    } else {
        map.addSource("mapbox-traffic", {
            url: "mapbox://mapbox.mapbox-traffic-v1",
            type: "vector",
        }).addLayer({
            id: "traffic",
            source: "mapbox-traffic",
            "source-layer": "traffic",
            type: "line",
            paint: {
                "line-width": 2,
                "line-color": [
                    "case",
                    ["==", "low", ["get", "congestion"]],
                    "#00FF00",
                    ["==", "moderate", ["get", "congestion"]],
                    "#FFFF00",
                    ["==", "heavy", ["get", "congestion"]],
                    "#FFA500",
                    ["==", "severe", ["get", "congestion"]],
                    "#FF0000",
                    "#000000",
                ],
            },
        });
    }
};
export const removeTrafficLayer = (map: mapboxgl.Map) => {
    document.getElementsByClassName("mapboxgl-ctrl-traffic")[0].classList.remove("mapboxgl-ctrl-active");
    map.removeLayer("traffic");
};

export const getCenterOfMapObject = (object) => {
    switch (object.geometry.type) {
        case "Point":
            return object.geometry.coordinates;
        case "LineString": {
            // Find middle of the linestring using the coordinates array middle and use that
            const midpointIndex = Math.round((object.geometry.coordinates.length - 1) / 2);
            const midpoint = object.geometry.coordinates[midpointIndex];
            return midpoint;
        }
        default: {
            // Calc the center of the polygon and use that
            const firstCoordinate = object.geometry.coordinates[0];
            return turf.center(turf.points(firstCoordinate)).geometry.coordinates;
        }
    }
};

export const addToBounds = async (object, currentBounds: mapboxgl.LngLatBounds) => {
    switch (object.geometry.type) {
        case "Point":
            return currentBounds.extend(object.geometry.coordinates);
        case "LineString":
            return Promise.all(
                object.geometry.coordinates.map((x) => {
                    currentBounds.extend(x);
                })
            );
        case "Polygon":
            return Promise.all(
                object.geometry.coordinates.map((x) => {
                    x.map((y) => {
                        currentBounds.extend(y);
                    });
                })
            );
    }
};
